
import React from "react";
import "./styles/index.scss";
import logoImg from "assets/imgs/maximnft_logo_white.png";
import { SocialIcon } from "components/common/common.styles";
import { FaDiscord, FaInstagram, FaTwitter } from "react-icons/fa";

interface AppProps {}

const SiteUpdating: React.FC<AppProps> = () => {

  return (
    <div className="site-updating">
      <img className="logo" src={logoImg} alt="logo" />
      <div>We're updating the website.<br />Stay connected!</div>
      <div className="d-flex mx-auto mx-md-0 mt-0">
        <SocialIcon className="mr-2 ml-2">
          <a href="https://discord.gg/J7SnkCVcej" target="_blank" rel="noreferrer">
            <FaDiscord/>
          </a>
        </SocialIcon>
        <SocialIcon className="mr-2 ml-2">
          <a href="https://www.instagram.com/themaximnft/" target="_blank" rel="noreferrer">
            <FaInstagram ></FaInstagram>
          </a>
        </SocialIcon>
        <SocialIcon className="mr-2 ml-2">
          <a href="https://twitter.com/maximnft" target="_blank" rel="noreferrer">
            <FaTwitter></FaTwitter>
          </a>
        </SocialIcon>
    </div>
    </div>
  );
};

export default SiteUpdating;
